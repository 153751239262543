<template>
  <div>
    <search-block title="отхода">
      <el-row :gutter="10">
        <el-col :span="24">
          <el-select
            clearable
            class="searchPole"
            v-model="search.waste"
            filterable
            value-key="organizationWasteId"
            placeholder="Код отхода"
          >
            <el-option
              v-for="item in organizationWastesList"
              :key="item.id"
              :label="`${item.dateFrom==null && item.dateTo==null ? '' : 
              ((item.dateFrom == null ? '...-' : ((10 > new Date(item.dateFrom).getDate()  ? '0':'')+new Date(item.dateFrom).getDate()+'.'+(9>new Date(item.dateFrom).getMonth() ? '0':'' )+(new Date(item.dateFrom).getMonth()+1)+'.'+new Date(item.dateFrom).getFullYear() + '-')) + (item.dateTo  == null ? '.../' : ((10 > new Date(item.dateTo).getDate()  ? '0':'')+new Date(item.dateTo).getDate()+'.'+(9>new Date(item.dateTo).getMonth() ? '0':'' )+(new Date(item.dateTo).getMonth()+1)+'.'+new Date(item.dateTo).getFullYear() + '/')))}${item.organizationWasteId}${prob}${item.ss01WasteDictionaryDTO.wasteName}${prob}${item.wasteOrgId}`"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
    </search-block>
    <el-form
      :rules="rules"
      v-if="search.waste !== null"
      :model="addWasteForm"
      ref="formedForm"
      label-position="top"
      label-width="120px"
    >
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label="Наименование отхода">
            <el-input
              disabled
              v-model="search.waste.ss01WasteDictionaryDTO.wasteName"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="Степень опасности">
            <el-input
              disabled
              v-model="
                search.waste.ss01WasteDictionaryDTO.dangerDegreeClassifierDTO
                  .name
              "
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="Код отхода">
            <el-input
              disabled
              v-model="search.waste.ss01WasteDictionaryDTO.wasteCode"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label="Ответственный эколог">
            <el-select
              clearable
              class="searchPole"
              v-model="addWasteForm.responsible"
              filterable
              value-key="id"
              placeholder="Ответственный"
            >
              <el-option
                v-for="item in usersByManagerList"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="19">
          <el-form-item
            label="Фактическое количество отходов, находящихся на хранении в данном подразделении на момент начало работы в программе." 
          >
            <el-input v-model="stored"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="5">
        <br><br>
          <el-form-item label=" Ед. измерения">
            <el-input
              disabled
              v-model="search.waste.pss93UnitInfo.name"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="19">
          <el-form-item
            label="В том числе на долговременном хранении" 
          >
            <el-input v-model="storedLong"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="5">
        <br><br>
          <el-form-item label=" Ед. измерения">
            <el-input
              disabled
              v-model="search.waste.pss93UnitInfo.name"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <br>
      <el-row >
        <el-date-picker 
                  v-model="addWasteForm.dateFrom"
                  type="date"
                  placeholder = "Дата начала работы"
                  value-format="yyyy-MM-dd"
        ></el-date-picker>
      </el-row>
      <br>
      <el-row>
        <input type="checkbox" id="checkbox" v-model="addWasteForm.datEd">
        <label for="checkbox">Устаревшее</label>
      </el-row>
      <br>
      <el-row >
        <el-date-picker 
                  v-model="addWasteForm.dateTo"
                  type="date"
                  :disabled="!addWasteForm.datEd"
                  placeholder = "Дата окончания работы"
                  value-format="yyyy-MM-dd"
        ></el-date-picker>
      </el-row>
      <el-row>
        <input type="checkbox" id="checkbox2" v-model="addWasteForm.alLocated" :disabled="!addWasteForm.datEd">
        <label for="checkbox2" :disabled="!addWasteForm.datEd">Распределено</label>
      </el-row>

      <el-row>
        <el-col :span="24">
          <el-button @click="addWaste" type="success">Добавить отход</el-button>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SearchBlock from "@/components/searchBlock";
import { AXIOS } from "@/AXIOS/http-common";
import notification from "@/mixins/notification";

export default {
  name: "techProcessesAddWasteForm",
  props: ["selectedTechProcess","action"],
  mixins: [notification],
  components: { SearchBlock },
  data() {
    return {
      SS01DictionaryValueSum: "0",
      prob:"\\ ",
      rules: {
        responsible: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],
      },
      dangerDegree: null,
      units: "",
      usersByManagerList: [],
      stored: 0,
      storedLong:0,
      search: {
        waste: null,
        code: null,
        name: null,
        loadingCodes: false,
        loadingNames: false,
      },
      techProcess: {
        processId: null,
      },
      addWasteForm: {
        responsible: null,
        techProcessWasteId: null,
        organizationWaste: null,
        stored: null,
        alLocated:false,
        dateFrom:null,
        dateTo:null,
        datEd:null,
      },
    };
  },

  computed: {
    ...mapGetters({
      ss01CodeList: "GETSSS01CODELIST",
      wasteNames: "GETWASTENAMES",
      foundedWaste: "GETSS01DICTIONARY",
      organizationWastesList: "GETORGANIZATIONWASTELIST",
    }),
  },


  methods: {
    getDangerDegree() {
      this.getSubClassifierById(1).then((response) => {
        this.dangerDegree = response.data.content;
      });
    },
    setval()
    {
      this.SS01DictionaryValueSum.valstoreher = parseFloat(this.stored.toString().replace(",", "."));
  },

    /*async getvalor()
    {
      console.log(this);
    return await AXIOS.get(
      "classifiers/ss01dictionary/SS01DictionaryValueSum?Id=" + this.search.waste.organizationWasteId+ "&tphw=" + this.techProcessHasWasteId
    ).then((response) => {
      console.log(response);
      this.SS01DictionaryValueSum = response.data;
    });
  },*/

    searchCode(query) {
      this.getCodeList(query);
    },

    searchWasteName(query) {
      this.getWasteNames(query);
    },

    async searchWasteByCode() {
      await this.$store.dispatch(
        "findSS01DictionaryValueByClassifierId",
        this.search.code.id
      );
      this.addWasteForm.organizationWaste = this.foundedWaste.content[0];
    },

    async searchWasteByName() {
      await this.$store.dispatch(
        "findSS01DictionaryValueByClassifierId",
        this.search.name.id
      );
      this.addWasteForm.organizationWaste = this.foundedWaste.content[0];
    },

    async getCodeList(query) {
      this.search.loadingCodes = true;
      await this.$store.dispatch("getCodeList", query);
      this.search.loadingCodes = false;
    },

    async getWasteNames(query) {
      this.search.loadingNames = true;
      await this.$store.dispatch("getWasteNames", query);
      this.search.loadingNames = false;
    },

    async getSubClassifierById(id) {
      let data = {
        id: id,
        size: 100,
        page: 0,
      };
      return await this.$store.dispatch(
        "getAllDataSubClassifierForSelect",
        data
      );
    },

    async getOrganizationWastesList() {
      await this.$store.dispatch(
        "getOrganizationWastesList",
        this.selectedTechProcess.processId
      );
    },

    addWaste() {
      this.$refs["formedForm"].validate((valid) => {
        if (valid) {
          let data = {
            processId: this.selectedTechProcess.processId,
            techProcessWasteDTO: [
              {
                storedLong: this.storedLong,
                organizationWasteId: this.search.waste.organizationWasteId,
                stored: this.stored,
                userInfo: this.addWasteForm.responsible,
                alLocated:this.addWasteForm.alLocated,
                dateFrom:this.addWasteForm.dateFrom,
                dateTo:this.addWasteForm.dateTo,
                datEd:this.addWasteForm.datEd,
              },
            ],
          };
          this.$store.dispatch("addWasteToTechProcess", data).then(() => {
            this.notification("Успешно", "Отход добавлен", "success");
            this.$store.dispatch("setShowModal", false);
            this.$store.dispatch("getListAllTechProcesses", {
              page: this.action.page,
              size: this.action.size,
            });
          });
        }
      });
    },
  },

  async mounted() {
    await AXIOS.get("/user/get/users/by/manager").then((response) => {
      console.log(response.data);
      this.usersByManagerList = response.data;
    });

    await this.getOrganizationWastesList();
    this.getDangerDegree();
  },
};
</script>

<style>

.el-scrollbar {
  max-width: 70vw !important;
}
</style>
